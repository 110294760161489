import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import LeafBorder from "../images/hand-painted-leaf-border-cut.png"

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 270px;
  margin-bottom: 32px;
  background-size: 270px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 1.45rem;
`

const Subheader = styled.h3`
  font-family: "Benton Sans";
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Tab = styled.span`
  padding: 0 15px;
`

class FridayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Friday | Sara & Tim" />
        <H1>FRIDAY, OCTOBER 22ND</H1>
        <LeafBorderPicture />
        <Subheader>Dinner and drinks</Subheader>
        <H4>
          <a href="https://www.thefoxgoesfree.com/">The Fox Goes Free</a>,
          Charlton Road, Nr Goodwood, West Sussex PO18 0HU
        </H4>
        <P>
          In order to make the most of everyone we love being in one place,
          we're planning some pre-wedding festivities. So come and join us for
          some food and warm-up drinks the night before the Big Day!
        </P>
        <P>
          The pub has asked us to choose food ahead of time to help them
          prepare. If you RSVP'd that you would be coming, we will be in touch
          soon to get your meal order. If you'd like to just come by for a drink
          (and not eat) that's fine too.
        </P>
        <P>
          18:15<Tab></Tab>Arrive and drinks
        </P>
        <P>
          18:45<Tab></Tab>Dinner served
        </P>
        <P>
          After<Tab></Tab>Drinks
        </P>
      </Layout>
    )
  }
}

export default FridayPage
